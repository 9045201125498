import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import showdown from 'showdown';

import Layout from "../components/layout";
import SEO from "../components/seo";


function IndexPage() {
  const { strapiHomepage } = useStaticQuery(
    graphql`
      query homepageDataQuery {
        strapiHomepage {
          PageContent
          PageDescription
          PageSubtitle
          PageTitle
          updated_at
        }
      }
    `
  );

  const getHtmlFromMarkdown = (markdown) => {
    const converter = new showdown.Converter();
    return converter.makeHtml(markdown);
  }

  return(
  <Layout>
    <SEO title={strapiHomepage.PageTitle} />
    <h1>{strapiHomepage.PageTitle}</h1>
    <p>{strapiHomepage.PageSubtitle}</p>
    <div dangerouslySetInnerHTML={{ __html: getHtmlFromMarkdown(strapiHomepage.PageContent) }}></div>
  </Layout>
  );
}

export default IndexPage
